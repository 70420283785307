<template>
  <div class="article__content">
    <template v-for="(block, index) in JSON.parse(text).blocks">
      <p :key="index" v-if="block.type === 'paragraph'" v-html="block.data.text"></p>
      <h1 :key="index" v-if="block.type === 'header' && block.data.level === 1">{{ block.data.text }}</h1>
      <h2 :key="index" v-if="block.type === 'header' && block.data.level === 2">{{ block.data.text }}</h2>
      <h3 :key="index" v-if="block.type === 'header' && block.data.level === 3">{{ block.data.text }}</h3>
      <h4 :key="index" v-if="block.type === 'header' && block.data.level === 4">{{ block.data.text }}</h4>
      <h5 :key="index" v-if="block.type === 'header' && block.data.level === 5">{{ block.data.text }}</h5>
      <h6 :key="index" v-if="block.type === 'header' && block.data.level === 6">{{ block.data.text }}</h6>
      <pre :key="index" v-if="block.type === 'code'">{{ block.data.code }}</pre>
      <blockquote class="article__content__quote" :key="index" v-if="block.type === 'quote'">
        <div class="article__content__quote__image">
          <span>{{ block.data.caption }}</span>
          <span></span>
        </div>
        <p>{{ block.data.text }}</p>
      </blockquote>
      <hr :key="index" v-if="block.type === 'delimiter'"/>
      <div :key="index" v-if="block.type === 'raw'" v-html="block.data.html"></div>
      <div :key="index" v-if="block.type === 'warning'" class="article__warning">
        <span>{{ block.data.title }}</span>
        <p>{{ block.data.message }}</p>
      </div>
      <table :key="index" v-if="block.type === 'table'">
        <tbody>
        <tr v-for="(row, i) in block.data.content" :key="i">
          <td v-for="(column, j) in row" :key="j">{{ column }}</td>
        </tr>
        </tbody>
      </table>
      <ul :key="index" v-if="block.type === 'list' && block.data.style === 'unordered'" class="article__list__common">
        <li v-for="(item, i) in block.data.items" :key="i">{{ item }}</li>
      </ul>
      <ol :key="index" v-if="block.type === 'list' && block.data.style ==='ordered'" class="article__list__common">
        <li v-for="(item, i) in block.data.items" :key="i">{{ item }}</li>
      </ol>
      <a :key="index" v-if="block.type === 'linkTool'" :href="block.data.link" target="_blank" class="article__link">
        <div>
          <span>{{ block.data.meta.title.length ? block.data.meta.title : block.data.link }}</span>
          <span v-if="block.data.meta.description.length">{{ block.data.meta.description }}</span>
        </div>
        <img v-if="block.data.meta.image.url.length" :src="block.data.meta.image.url" :alt="block.data.meta.title"/>
      </a>
      <div :key="index" v-if="block.type === 'image'" :class="{ 'article__image--stretched': block.data.stretched }">
        <img :src="$store.state.api + block.data.file.url" :alt="block.data.caption" class="article__image" :class="{ 'article__image--stretched': block.data.stretched, 'article__image--left': block.data.left, 'article__image--right': block.data.right}"/>
        <span>{{ block.data.caption }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'EditorJSComponent',
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>
